.module-A2PB {
  display: flex;
  width: 100%;
  height: 100%;

  > div {
    width: 100%;
    height: 100%;
    position: relative;
    background-color: #f8f8f8;
    border: 1px solid #bbb;
    border-radius: 4px;
    box-sizing: border-box;

    &::before {
      position: absolute;
      display: block;
      content: "";
      width: 30px;
      height: 4px;
      bottom: 8px;
      right: 8px;
      background: #bbb;
    }

    &:nth-child(2) {
      &::before {
        right: initial;
        left: 8px;
      }
    }
  }
}
