.cabinet-container {
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .cabinet-container__header {
    margin-bottom: 10px;

    > label {
      > input {
        width: 80px;
      }
    }

    > select {
      height: 24px;
      font-size: 16px;
    }

    > button {
      font-size: 16px;
    }
  }

  .cabinet-container__top {
    position: relative;
    display: flex;
    align-items: center;
    border-radius: 4px;
    display: flex;
    width: 500px;
    // border: 4px solid #ddd;
    background-color: #f2f2f2;
    padding: 0px;
    box-sizing: content-box;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 4px;
      border: 4px solid #ddd;
      box-sizing: border-box;
    }

    &.is-smaller::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 4px;
      border: 2px dashed black;
      @apply border-red-400 #{!important};
      box-sizing: border-box;
      z-index: 999;
    }

    .module {
      position: relative;
      // height: calc(100% + 8px);
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &:first-of-type {
        // margin-left: -4px;
      }

      &:last-of-type {
        // margin-right: -4px;
      }

      &:hover {
        .module__actions {
          display: block;
        }
      }

      &.is-dragging {
        opacity: 0.8;
      }

      .module__actions {
        position: absolute;
        display: none;

        > button {
          width: 24px;
          height: 24px;
        }
      }

      .module-remove {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
      }

      .module-controls {
        height: 18px;
        padding: 0 1px;

        > input {
          width: 50px;
        }

        .width-mark {
          position: relative;
          height: 2px;
          width: 100%;
          display: flex;
          justify-content: center;

          &::before,
          &::after {
            content: "";
            position: absolute;
            top: -3px;
            width: 2px;
            height: 8px;
            @apply bg-green-400 #{!important};
          }

          &::before {
            left: 0;
          }

          &::after {
            right: 0;
          }
        }
      }
    }
  }
}
